@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    margin: 0px;
    padding: 0px;
}

.header-bar {
    background-color: rgba(0, 196, 230, .2);
    box-shadow: 0px 1px 6px 3px rgba(0, 0, 0, 0.19);
    position: relative;
    z-index: 10;
}

.header-bar nav {
    background-color: rgba(0,0,0,0);
}

.row {
    display: flex;
    flex-direction: row;
}

.row-inline {
    display: flex-inline;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.column-inline {
    display: inline-flex;
    flex-direction: column;
}

.v-center{
    justify-content: center;
}

.h-center {
    align-content: center;
}

.h-justify-space-between {
    justify-content: space-between;
}

.default-padding {
    padding: 6px 12px;
}

.text-align-center {
    text-align: center;
}

.menu-header {
    top: 0px;
    right: 0px;
    left: 220px;
    height: 100px;
    position: fixed;
}

.menu-link-group {
    list-style: none;
    background-color: rgba(0,0,0,0);
    padding: 0px;
    margin: 0px;
    align-items: center;
}

.menu-link-group li a {
    padding: 12px 6px;
    text-decoration: none;
    font-family: "IBM Plex Mono", monospace;
    font-weight: 400;
    font-style: normal;
    margin-left: 6px;
}

.menu-link-group li:hover {
    text-decoration: none;
    color: #ffffff;
    transform: scale(1.2);
    transition: all 200ms linear 0s;
}

.menu-link-group li:hover a {
    color: #000000;
    text-decoration: underline;
}

.menu-link-group li:visited {
    color: #000000;
}

.menu-header-logo {
    width: 220px;
    height: 75px;
    background-position: center center;
    background-image: url(../images/tecolotesoft-logo.png);
    background-size: 150px 70.5px;
    background-repeat: no-repeat;
}

.body-content {
    position: fixed;
    top: 75px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #FFFFFF;
    padding: 12px 24px;
    margin-top: 12px;
}

.logout-btn {
    background-image: url("../images/logout.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.login-btn {
    background-image: url("../images/login.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.profile-text {
    font-family: "IBM Plex Mono", monospace;
    font-weight: 400;
    font-style: normal;
}

.dialog-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}

.close-icon {
    background: url("../images/close-icon.svg");
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.close-dialog-button {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
}