/* src/components/AuthBox.css */

.auth-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-box h2 {
    margin-bottom: 20px;
    color: #333333;
}

.auth-box form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.auth-box input {
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
}

.auth-box button {
    padding: 12px;
    margin: 16px 0;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.auth-box button:hover {
    background-color: #45a049;
}

.social-login {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.social-login > * {
    margin: 8px 0;
}

.facebook-button {
    padding: 12px;
    background-color: #3b5998;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.facebook-button:hover {
    background-color: #334d84;
}

.auth-box p {
    margin-top: 20px;
    color: #666666;
}

.auth-box p span {
    color: #007BFF;
    cursor: pointer;
    text-decoration: underline;
}

.center-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}